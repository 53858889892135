<template>
	<div class="policyCompilation">
		<div class="policyCompilationTitle">电子政策汇编系统</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="政策专栏列表" name="first">
				<ColumList></ColumList>
			</el-tab-pane>
			<el-tab-pane label="本地政策列表" name="second">
				<MaterialList></MaterialList>
			</el-tab-pane>
			<el-tab-pane label="全网政策列表" name="three">
				<AllOnline></AllOnline>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import ColumList from '../components/columList.vue'
	import MaterialList from '../components/materialList.vue'
	import AllOnline from '../components/allOnline.vue'
	export default{
		data(){
			return{
				activeName:localStorage.getItem('activeName')?localStorage.getItem('activeName'):'first'
			}
		},
		components:{
			ColumList,
			AllOnline,
			MaterialList
		},
		mounted(){
			if(localStorage.getItem('activeName')== ''){
				localStorage.setItem('activeName','first')
			}
		},
		methods:{
			handleClick(tab){
				localStorage.setItem('activeName',tab.name);
			}
		}
	}
</script>

<style scoped>
	.policyCompilationTitle{
		display: flex;
		align-items: flex-start;
		justify-content: center;
		font-size: 18px;
		height: 40px;
	}

</style>
<style>
	body .el-table th.gutter {
		display: table-cell !important;
	}

	body .el-table colgroup.gutter{
		display: table-cell!important;
	}
</style>
