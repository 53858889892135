<template>
	<!-- 政策专栏列表 -->
	<div class="container columlist-wrap">
		<div class="top">
			<div style="display: flex;">
				<el-input v-model="keyword" size="small" placeholder="输入专栏标题,描述"></el-input>
				<el-button type="primary" icon="el-icon-search" size="small" style="margin-left:5px;" @click="searchHandle">搜索</el-button>
			</div>
			<div>
				<el-button type="primary" icon="el-icon-plus" size="small" @click="clickHandle">新增政策</el-button>
			</div>
		</div>

		<div class="main">
			<el-table border :data="list" style="width: 100%;">
				<el-table-column label="专栏标题" align="center" prop='title'></el-table-column>
				<el-table-column label="专栏描述" align="center" prop='description' show-overflow-tooltip></el-table-column>
				<el-table-column label="政策数量" align="center" prop='articles_count'></el-table-column>
				<el-table-column label="专栏排序" align="center" prop='display_order'></el-table-column>
				<el-table-column label="创建时间" align="center" prop='create_date'></el-table-column>
				<el-table-column label="操作" align="center" width="400">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="bianji(scope.row)">编辑</el-button>
						<el-button :type="scope.row.status?'primary':'danger'" size="mini" @click="qiyong(scope.row)">{{scope.row.status?'启用':'禁用'}}</el-button>
						<el-button type="primary" size="mini" @click.native.prevent="deleteRow(scope.$index,list,scope.row)">删除</el-button>
						<el-button type="primary" size="mini" @click="paixu(scope.row)">修改政策</el-button>
						<el-button type="primary" size="mini" @click="addColumnData(scope.row)">添加政策</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pages">
				<el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-count="pageCount" layout="total,prev,pager,next,jumper"
				 :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog :show-close="falseShow" :visible.sync="dialogColumList" width="80%">
			<div style="margin-bottom: 10px;">
				<el-button :plain="plainShow" type="primary" size="mini" @click="localClickChange">本地政策</el-button>
				<el-button :plain="!plainShow" type="primary" size="mini" @click="allDataClickChange">全网政策</el-button>
			</div>
				<el-table v-loading="loadingArea" border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" align="center" width="50"></el-table-column>
					<el-table-column align="center" label="图片" width="80">
						<template slot-scope="scope">
							<img :src="scope.row.img" width="40" height="40" class="head_pic" />
						</template>
					</el-table-column>
					<el-table-column prop="title" show-overflow-tooltip align="center" label="标题" width="120"></el-table-column>
					<el-table-column prop="name" align="center" label="已存在专栏" width="120">
						<template slot-scope="scope">
							<el-tag size="mini" type="info" style="margin-top:10px;" v-for="(item,index) in scope.row.article_types" :key="index">{{item.title}}</el-tag>
							<div style="font-size: 12px;color: #ddd" v-if="scope.row.article_types.length === 0">暂无所属专栏</div>
						</template>
					</el-table-column>
					<el-table-column prop="name" align="center" label="政策有效日期" width="200">
						<template slot-scope="scope">
							<div style="display: flex;flex-direction: column;">
								<span>开始时间：{{scope.row.policy_start_date}}</span>
								<span>结束时间：{{scope.row.policy_end_date}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="organ_name" align="center" label="发布单位" width="120"></el-table-column>
					<el-table-column prop="name" align="center" label="适用地域" >
						<template slot-scope="scope">
							<el-tag size="mini" type="info" style="margin-top:10px;" v-for="(item,index) in scope.row.areas" :key="index">{{item.name}}</el-tag>
							<div style="font-size: 12px;color: #ddd" v-if="scope.row.areas.length === 0">暂无适用地域</div>
						</template>
					</el-table-column>
					<el-table-column prop="address" align="center" label="类型">
						<template slot-scope="scope">
							<el-tag size="mini" type="info" style="margin-top:10px;" v-for="(item,index) in scope.row.policyTypes" :key="index">{{item.title}}</el-tag>
							<div style="font-size: 12px;color: #ddd" v-if="scope.row.policyTypes.length === 0">暂无类型</div>
						</template>
					</el-table-column>
				</el-table>
			<Page
				@currentPageChange="currentPageChange"
				:pageSize="tableDataPage.per_page"
				:page="tableDataPage.current_page"
				:total="tableDataPage.total"
				style="text-align:center;margin-top: 10px;"
			></Page>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogColumList = false">取 消</el-button>
				<el-button :loading="submitLoading" type="primary" @click="submitLocalData">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import Page from '@/components/PageComponents.vue'
	export default {
		data() {
			return {
				falseShow: false,
				keyword: '',
				dialogVisible: false,
				options: [],
				origin_id: '',
				id: '',
				list: [],
				currentPage: 1,
				pageCount: Math.ceil(this.total / 10),
				total: 0,
				page: 1,
				limit: 10,
				// 精准招商3期
				dialogColumList: false,
				tableData: [],
				tableDataPage: {},
				organ_ids: [],
				submitLoading: false,
				tableDataId: '', // 点击某一个专栏的id
				plainShow: false,
				url: this.URL.adminUrl.add_column_local_list,
				loadingArea: false
			}
		},
		mounted() {
			this.origin_id = sessionStorage.organizationId;
			this.getData();
		},
		components: {
			Page
		},
		methods: {
			// 点击本地的那个按钮
			localClickChange() {
				if (!this.plainShow){
					return false
				}
				this.loadingArea = true
				this.plainShow = !this.plainShow
				this.url = this.URL.adminUrl.add_column_local_list
				this.organ_ids = []
				this.getColumnListData(this.tableDataId, 1)
			},
			// 点击全网的按钮
			allDataClickChange() {
				if (this.plainShow){
					return false
				}
				this.loadingArea =  true
				this.plainShow = !this.plainShow
				this.url = this.URL.adminUrl.add_column_all_list
				this.organ_ids = []
				this.getColumnListData(this.tableDataId, 1)
			},
			// 弹窗的确认按钮提交
			submitLocalData() {
				this.submitLoading = true
				this.$http.post(this.URL.adminUrl.submit_column_local + this.tableDataId, {article_ids: this.organ_ids}).then(r => {
					this.organ_ids = []
					this.submitLoading = this.dialogColumList = false
					this.$message({
						type: 'success',
						message: '本地政策素材添加成功!'
					});
				}).catch(() =>{
					this.organ_ids = []
					this.submitLoading = false
					console.log('空间等空的')
					this.$notify.error({
						title: '错误',
						message: '本地政策素材添加失败'
					});
				})
			},
			addColumnData(d) {
				this.dialogColumList = true
				this.tableDataId = d.id
				this.loadingArea = true
				this.getColumnListData(d.id)
			},
			// 获取弹窗的本地政策列表
			async getColumnListData(id, page) {
				let {data} = await this.$http.get(this.url + id, {params: {page, organ_id: this.origin_id,}})
				this.tableData = data.data.data
				this.tableDataPage = data.data.meta
				this.loadingArea = false
				this.organ_ids.forEach(d =>{
					this.tableData.forEach((t, i) =>{
						if (t.id == d) {
							const time = setTimeout(()=> {
								this.$refs.multipleTable.toggleRowSelection(this.tableData[i])
								clearTimeout(time)
							}, 1)
						}
					})
				})
			},
			// 弹窗的选择功能
			handleSelectionChange(d) {
				this.tableData.forEach(d =>{
					const index = this.organ_ids.indexOf(d.id)
					if (index > -1){
						this.organ_ids.splice(index, 1)
					}
				})
				d.forEach(item =>{
					this.organ_ids.push(item.id)
				})
				this.organ_ids = Array.from(new Set(this.organ_ids))
			},
			currentPageChange(d) {
				this.loadingArea = true
				this.getColumnListData(this.tableDataId, d)
			},
			getData() {
				this.$http.get(this.URL.adminUrl.article_types, {
					params: {
						organ_id: this.origin_id,
						from: 'manager',
						page: this.page,
						limit: this.limit,
						keyword: this.keyword
					}
				}).then((res) => {
					this.total = res.data.data.meta.total
					this.list = res.data.data.article_types;
				})
			},
			clickHandle(e) {
				this.$router.push('../addArticals');
			},
			paixu(row) {
				this.$router.push('../policySort?id=' + row.id);
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getData();
			},
			searchHandle() {
				this.getData();
			},
			bianji(row) {
				this.$router.push('../addArticals?id=' + row.id)
			},
			deleteRow(index, rows, row) {
				this.$confirm('确认删除', '提示', {
				}).then(() => {
					this.$http.delete(this.URL.adminUrl.articleActiveUpdata + row.id, {
						params: {
							from: 'manager',
							organ_id: this.origin_id
						}
					}).then((res) => {
						rows.splice(index, 1);
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});

			},
			qiyong(row) {
				let txt = row.status ? '禁用' : '启用';
				this.id = row.id;
				this.$confirm('是否' + txt, '提示', {
				}).then(() => {
					this.$http.post(this.URL.adminUrl.articleActiveUpdata + this.id, {
						from: 'manager',
						organ_id: this.origin_id,
						status: row.status == 1 ? 0 : 1
					}).then((res) => {
						row.status = row.status == 1 ? 0 : 1;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		}
	}
</script>

<style scoped lang="less">
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.pages {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 50px 0;
	}

</style>
<style lang="less">
	.columlist-wrap{
		.el-dialog__footer{
			text-align: center;
			.el-button+.el-button{
				margin-left: 50px;
			}
		}
	}
</style>
