<template>
	<div class="container">
		<div class="top">
			<div style="display: flex;">
				<el-input v-model="keyword" size="small" placeholder="输入专栏标题、所属专栏名称"></el-input>
				<el-button type="primary" icon="el-icon-search" size="small" style="margin-left:5px;" @click="searchHandle">搜索</el-button>
			</div>
			<div>
				<el-button type="primary" icon="el-icon-plus" size="small" @click="clickHandle">新增政策素材</el-button>
			</div>
		</div>
		<div class="main">
			<el-table border :data="list" style="width: 100%;">
				<el-table-column label="图片" align="center">
					<template slot-scope="scope">
						<img :src="scope.row.img" width="40" height="40" class="head_pic" />
					</template>
				</el-table-column>
				<el-table-column label="标题" align="center" prop='title' width="240" show-overflow-tooltip></el-table-column>
				<el-table-column label="所属专栏" align="center" prop='article_types' width="200">
					<template slot-scope="scope">
						<el-tag size="mini" type="info" style="margin-top:10px;" v-for="(item,index) in scope.row.article_types" :key="index">{{item.title}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="政策有效日期" align="center" width="180">
					<template slot-scope="scope">
						<div style="display: flex;flex-direction: column;">
							<span>开始时间：{{scope.row.policy_start_date}}</span>
							<span>结束时间：{{scope.row.policy_end_date}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="政策申报人数" align="center" prop='user_policy_applies_count'>
					<template slot-scope="scope">
						<span @click="shengbao(scope.row)" style="color: #409EFF;text-decoration: underline;cursor: pointer">{{scope.row.user_policy_applies_count}}</span>
					</template>
				</el-table-column>
				<el-table-column label="匹配企业/项目数" align="center" prop='project_count'>
					<template slot-scope="scope">
						<span @click="toJump(scope.row,'proVisible')" style="color: #409EFF;text-decoration: underline;cursor: pointer">{{scope.row.project_count}}</span>
					</template>
				</el-table-column>
				<el-table-column label="匹配空间数" align="center" prop='space_count'>
					<template slot-scope="scope">
						<span @click="toJump(scope.row,'spaceVisible')" style="color: #409EFF;text-decoration: underline;cursor: pointer">{{scope.row.space_count}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="250">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="bianji(scope.row)">编辑</el-button>
						<el-button type="primary" size="mini" @click="fabu(scope.$index,scope.row)">发布</el-button>
						<el-button type="primary" size="mini" @click.native.prevent="deleteRow(scope.$index,list,scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pages">
				<el-pagination
					  background
				      @current-change="handleCurrentChange"
				      :current-page="1"
					  :page-count="pageCount"
					  v-if="list.length>0"
				      layout="total,prev,pager,next,jumper"
				      :total="total">
				    </el-pagination>
			</div>

		</div>

		<el-dialog title="请选择你要发布的专栏" :visible.sync="dialogFormVisible">
			<el-form>
				<el-checkbox-group v-model="article_type">
					<el-checkbox :label="item.id" :key="item.id" name="type" v-for="item in zhuanlan">{{item.title}}</el-checkbox>
				</el-checkbox-group>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="queding">确 定</el-button>
			</div>
		</el-dialog>
<!--		 企业项目弹框-->
		<el-dialog  :visible.sync="proVisible" width="80%" @close="closePro">
			<el-table border :data="proData" style="width: 100%;">
				<el-table-column label="企业名称" align="center" prop='pro_company_name' min-width="100" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="项目名称" align="center" prop='pro_name' min-width="100" show-overflow-tooltip></el-table-column>
				<el-table-column label="品牌名称" align="center" min-width="100" show-overflow-tooltip>
					<template slot-scope="scope">
						<span v-for="(item,index) in scope.row.brand" style="margin:3px;" :key="index">{{item.title}}</span>
					</template>
				</el-table-column>
				<el-table-column label="领域" align="center"  min-width="100">
					<template slot-scope="scope">
						<span  v-for="(item,index) in scope.row.morph_industry" :key="index">{{item.title}}</span>
					</template>
				</el-table-column>
				<el-table-column label="所属地区" align="center" prop='title' min-width="140" show-overflow-tooltip>
					<template slot-scope="scope">
						<span style="margin:3px;" v-for="(item,index) in scope.row.morph_area" :key="index">{{item.title}}</span>
					</template>
				</el-table-column>
				<el-table-column label="融资阶段" align="center"  min-width="140" show-overflow-tooltip>
					<template slot-scope="scope">
						<span style="margin:3px;" v-for="(item,index) in scope.row.stage" :key="index">{{item.title}}</span>
					</template>
				</el-table-column>
				<el-table-column label="得分" align="center" prop='pro_total_score' min-width="140" show-overflow-tooltip></el-table-column>
				<el-table-column label="潜在投资方" align="center" prop='potential_users_counts'>
					<template slot-scope="scope">
						<span @click="toJumpPage(scope.row,'jumpInvest')" style="color: #409EFF;text-decoration: underline;cursor: pointer">{{scope.row.potential_users_counts}}</span>
					</template>
				</el-table-column>
				<el-table-column label="适用政策" align="center" prop='policy_article_match_project_counts'>
					<template slot-scope="scope">
						<span @click="toJumpPage(scope.row,'jumpPolicy')" style="color: #409EFF;text-decoration: underline;cursor: pointer">{{scope.row.policy_article_match_project_counts}}</span>
					</template>
				</el-table-column>
				<el-table-column label="落地园区" align="center" prop='space_match_project_counts'>
					<template slot-scope="scope">
						<span @click="toJumpPage(scope.row,'jumpSpace')" style="color: #409EFF;text-decoration: underline;cursor: pointer">{{scope.row.space_match_project_counts}}</span>
					</template>
				</el-table-column>
				<el-table-column label="匹配度" align="center" prop='title' min-width="140" show-overflow-tooltip>
					<template slot-scope="scope">
						<span style="color: #409EFF;">{{scope.row.weight?scope.row.weight:0}}%</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" >
					<template slot-scope="scope">
						<el-button type="primary" size="mini" disabled v-if="scope.row.is_follow>0" @click="setProgress(scope.row)">已跟进</el-button>
						<el-button type="primary" size="mini" v-else  @click="setProgress(scope.row)">跟进</el-button>
					</template>
				</el-table-column>
			</el-table>
			<Pages  @currentPageChange="proTanChange"
					  :pageSize="pro_limit"
					  :page="proMeta.current_page"
					  :total="proMeta.total"
					  v-if="proData.length>0"
					style="margin:20px auto;text-align: center;"
					/>
		</el-dialog>
<!--		空间弹框-->
		<el-dialog  :visible.sync="spaceVisible" width="80%">
			<el-table border :data="spaceData" style="width: 100%;">
				<el-table-column label="空间名称" align="center" prop='name' min-width="100" show-overflow-tooltip></el-table-column>
				<el-table-column label="空间类型" align="center"  min-width="100" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-tag type="info" v-for="(item,index) in scope.row.park_type" :key="index">{{item}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="空间面积" align="center" prop='measure' min-width="100" show-overflow-tooltip>
					<template slot-scope="scope">
						<span>{{scope.row.measure}}㎡</span>
					</template>
				</el-table-column>
				<el-table-column label="地理位置" align="center" prop='title' min-width="140" show-overflow-tooltip>
					<template slot-scope="scope">
						<span>{{scope.row.province}}{{scope.row.city}}{{scope.row.district}}{{scope.row.town}}</span>
					</template>
				</el-table-column>
				<el-table-column label="常用手机" align="center" prop='mobile' min-width="140" show-overflow-tooltip></el-table-column>
				<el-table-column label="电话" align="center" prop='tel' min-width="140" show-overflow-tooltip></el-table-column>
				<el-table-column label="状态" align="center" prop='rent_state' min-width="100">

				</el-table-column>
				<el-table-column label="匹配度" align="center"  min-width="140" show-overflow-tooltip>
					<template slot-scope="scope">
						<span style="color: #409EFF;">{{scope.row.weight?scope.row.weight:0}}%</span>
					</template>
				</el-table-column>

			</el-table>
			<Pages  @currentPageChange="spaceTanChange"
					:pageSize="space_limit"
					:page="spaceMeta.current_page"
					:total="spaceMeta.total"
					v-if="spaceData.length>0"
					style="margin:20px auto;text-align: center;"
			/>
		</el-dialog>
	</div>
</template>

<script>
	import Pages from "@/components/PageComponents"; //分页
	import {
		success,
		error,
		warning
	} from "@/utils/notification";
	export default {
		data() {
			return {
				keyword: '',
				dialogFormVisible: false,
				zhuanlan: [],
				article_type: [],
				list: [],
				origin_id: "",
				pageCount:Math.ceil(this.total/10),
				total:0,
				page:1,
				limit:10,
				id:'',
				index:'',
				proVisible:false,
				spaceVisible:false,
				proData:[
				],
				spaceData:[ // 临时用，接口联调时删除
				],
				proPage:1,
				policy_id:0,
				pro_limit:15,
				spacePage:1,
				space_limit:15,
				proMeta:{},
				spaceMeta:{}
			}
		},
		components:{
			Pages
		},
		created() {
			console.log(sessionStorage.backName, '等等')
			if(sessionStorage.backName&&sessionStorage.backName==='showPro'){
				this.proVisible = true
                this.proPage = sessionStorage.policy_propage
				this.get_policy_prolist(sessionStorage.policy_id)
				this.policy_id = sessionStorage.policy_id?sessionStorage.policy_id:0
			}
		},
		mounted() {
			this.origin_id = sessionStorage.organizationId;
			this.getData();
		},
		methods: {
			closePro(){ // 关闭项目弹框
				sessionStorage.removeItem('backName')
				sessionStorage.removeItem('policy_id')
				sessionStorage.removeItem('policy_proName')
				sessionStorage.removeItem('policy_propage')
			},
			toJump(row,show){ // 项目数 和 空间数 控制弹框变量
				this[show] = true
                if (show ==='proVisible') {
                	this.get_policy_prolist(row.id)
					this.policy_id = row.id
					sessionStorage.policy_id = row.id
				} else {
                	// 空间列表接口调用
					this.get_policy_spacelist(row.id)
					this.policy_id = row.id
					// this.get_policy_spacelist(289)
				}
			},
			get_policy_spacelist(id){ // 获取政策 空间列表
				this.$http.get(this.URL.adminUrl.policy_space_list+id+'/space',{params:{
					limit:15,page:this.spacePage
					}})
				.then(res=>{
					this.spaceData = res.data.data.space
					this.spaceMeta = res.data.data.meta
				})
				.catch(err=>{
					error('请求数据失败，请联系管理员')
					console.log(err)
				})
			},
			get_policy_prolist(id){ // 获取政策下的项目列表
               this.$http.get(this.URL.adminUrl.policy_prolist+id,{params:{
               	page:this.proPage,
			    organ_id:sessionStorage.organizationId,
               	limit:15}
			   }).then(res=>{
				   this.proData = res.data.data.data
				   this.proMeta = res.data.data.meta
			   })
			   .catch(err=>{
			   	    error('请求数据失败，请联系管理员')
					console.log(err)
			   })
			},
			toJumpPage(row,path){ // 页面跳转
				sessionStorage.policy_proName = row.pro_name
				sessionStorage.policy_propage = this.proPage
				this.$router.push({
					path:'/'+path,
					query:{
						id:row.project_id
					}
				})
			},
			proTanChange(val){ // 页数改变
               this.proPage = val
				this.get_policy_prolist(this.policy_id)
			},
			spaceTanChange(val){ // 页数改变
               this.spacePage = val
				this.get_policy_spacelist(this.policy_id)
			},
			setProgress(row){ // 跟进事件
				// if(row.is_follow*1>0){
				// 	warning('您已跟进')
				// 	return false
				// }
				let project_id = row.project_id*1,organ_id=sessionStorage.organizationId*1
               this.$http.post(this.URL.adminUrl.policy_pro_genjin,{project_id:project_id,organ_id:organ_id})
				.then(res=>{
					// console.log(res,'genjin')
					success('跟进成功')
					this.get_policy_prolist(this.policy_id)
				})
				.catch(err=>{
					error('请求数据失败，请联系管理员')
					console.log(err)
				})
			},
			getData(){
				this.$http.get(this.URL.adminUrl.articleList + this.origin_id,{
					params:{
						page:this.page,
						limit:this.limit,
						keyword:this.keyword
					}
				}).then((res) => {
					this.total = res.data.data.meta.total
					this.list = res.data.data.data
				});
			},
			searchHandle(){
				this.getData();
			},
			handleCurrentChange(val){
				this.page = val;
				this.getData();
			},
			clickHandle() {
				this.$router.push('/addMaterial')
			},
			fabu(index,row) {
				this.id = row.id;
				this.index = index;
				this.article_type =[];
				this.$http.get(this.URL.adminUrl.article_types, {
					params: {
						from: 'manager',
						organ_id: this.origin_id
					}
				}).then((res) => {
					this.zhuanlan = res.data.data.article_types;
					this.list[index].article_types.forEach(item=>{
						this.article_type.push(item.id);
					})
					this.dialogFormVisible = true;
				})
			},
			bianji(row) {
				this.$router.push({
					path: '/addMaterial',
					query: {
						id: row.id
					}
				})
			},
			queding() {
				this.$http.put(this.URL.adminUrl.PolicyArticle+this.id,{
					article_type:this.article_type
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '发布成功!'
					});
					this.$http.get(this.URL.adminUrl.PolicyArticleYes+this.id).then((res)=>{
						this.list[this.index].article_types=res.data.data
					})
					this.dialogFormVisible = false;
				})
			},
			deleteRow(index, rows, item) {
				this.$confirm('确认删除', '提示',{
				}).then(() => {
					this.$http.delete(this.URL.adminUrl.delPolicyArticle + item.id).then((res) => {
						rows.splice(index, 1);
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});



			},
			shengbao(row) {
				this.$router.push('/policyDeclaration?id='+row.id)
			}
		}
	}
</script>

<style scoped>
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	.el-checkbox {
		margin-bottom: 20px;
	}

	.el-tag {
		margin:3px;
	}

	.dateColumn {
		display: flex;
		flex-direction: column;
	}
	.pages{
		display: flex;
		align-items: center;
		justify-content: center;
		padding:50px 0;
	}
</style>
